<template>
    <div class="card-list-component">
        <div class="card-list">
            <template v-for="(task,i) in list">
                <div class="item" :key="i" @click="executeAction('goto', task)">
                    <div class="name-status">
                        <el-tooltip :content="task.title" placement="top-start">
                            <div class="name">{{task.title}}</div>
                        </el-tooltip>
                    </div>
                    <div class="info-content">
                        <div class="left">
                            <div class="info">
                                <span class="language" v-if="LANGUAGE_DICT">
                                    {{LANGUAGE_DICT[task.sourceLang].shortName}}
                                    <svg-icon name="ib-arrow-right" style="font-size:12px;margin:0 4px;"></svg-icon>
                                    {{LANGUAGE_DICT[task.targetLang].shortName}}
                                </span>
                                <span class="split-line"></span>
                                <span style="font-weight: 600;">
                                    <template v-if="type === 'qa'">
                                        {{(task.aiCount||0) + (task.basicCount||0)}}
                                    </template>
                                    <template v-else-if="type === 'termExtraction'">
                                        {{task.resultCount||0}}
                                    </template>
                                    <template v-else-if="type === 'detector'">
                                        {{task.sampleSize||0}}
                                    </template>
                                    <template v-else>
                                        {{task.sentenceCount||0}}
                                    </template>
                                     {{type === 'qa'?'个错误':type === 'termExtraction'?'个术语':'句段'}}
                                </span>
                            </div>
                            <div class="time">{{task.createTime}}</div>
                        </div>
                        <div class="right">
                            <div class="type-btn">
                                <template v-if="type === 'align'">
                                    <svg-icon name="ib-data-align"></svg-icon>
                                </template>
                                <template v-if="type === 'termExtraction'">
                                    <svg-icon name="ib-term-e"></svg-icon>
                                </template>
                                <template v-if="type === 'qa'">
                                    <svg-icon name="ib-tools-qa"></svg-icon>
                                </template>
                                <template v-if="type === 'detector'">
                                    <svg-icon name="ib-search"></svg-icon>
                                </template>
                            </div>
                            <el-dropdown placement="bottom" @command="(command)=>handleMoreCommand(command,task)">
                                <div class="more-btn" @click.stop>
                                    <svg-icon name="ib-more"></svg-icon>
                                </div>
                                <el-dropdown-menu slot="dropdown">
                                    <template v-if="type === 'align'">
                                        <el-dropdown-item command="goto" v-permission="`tools:alignmentRouter:detail`">{{$t('tools.component_CardList_tips_1')}}</el-dropdown-item>
                                        <el-dropdown-item command="excel" v-permission="`tools:alignmentRouter:downloadExcel`" divided v-if="task.allowDownload !== false">{{$t('tools.component_CardList_tips_2')}}</el-dropdown-item>
                                        <el-dropdown-item command="tmx" v-permission="`tools:alignmentRouter:downloadTMX`" v-if="task.allowDownload !== false">{{$t('tools.component_CardList_tips_3')}}</el-dropdown-item>
                                        <el-dropdown-item command="rename" v-permission="`tools:alignmentRouter:rename`">{{$t('tools.component_CardList_tips_4')}}</el-dropdown-item>
                                        <el-dropdown-item command="delete" v-permission="`tools:alignmentRouter:delete`" divided style="color:#F56C6C;">{{$t('tools.component_CardList_tips_5')}}</el-dropdown-item>
                                    </template>
                                    <template v-else-if="type === 'termExtraction'">
                                        <el-dropdown-item command="goto" v-permission="`tools:extractionRouter:detail`">{{$t('tools.component_CardList_tips_1')}}</el-dropdown-item>
                                        <el-dropdown-item command="excel" divided v-permission="`tools:extractionRouter:downloadExcel`" v-if="task.allowDownload !== false">{{$t('tools.component_CardList_tips_2')}}</el-dropdown-item>
                                        <el-dropdown-item command="tbx" v-permission="`tools:extractionRouter:downloadExcel`">{{$t('tools.component_CardList_tips_6')}}</el-dropdown-item>
                                        <el-dropdown-item command="delete" divided v-permission="`tools:extractionRouter:delete`" style="color:#F56C6C;">{{$t('tools.component_CardList_tips_5')}}</el-dropdown-item>
                                    </template>
                                    <template v-else-if="type === 'qa'">
                                        <el-dropdown-item command="goto" v-permission="`tools:checkRouter:detail`">{{$t('tools.component_CardList_tips_1')}}</el-dropdown-item>
                                        <el-dropdown-item command="excel" divided v-permission="`tools:check:detailRouter:download`" v-if="task.allowDownload !== false">{{$t('tools.component_CardList_tips_2')}}</el-dropdown-item>
                                        <el-dropdown-item command="delete" divided v-permission="`tools:checkRouter:delete`" style="color:#F56C6C;">{{$t('tools.component_CardList_tips_5')}}</el-dropdown-item>
                                    </template>
                                    <template v-else-if="type === 'detector'">
                                        <el-dropdown-item command="goto" v-permission="`tools:detectorRouter:detail`">{{$t('tools.component_CardList_tips_1')}}</el-dropdown-item>
                                        <el-dropdown-item command="excel" v-permission="`tools:detectorRouter:download`" divided v-if="task.allowDownload !== false">{{$t('tools.component_CardList_tips_2')}}</el-dropdown-item>
                                        <el-dropdown-item command="delete" divided v-permission="`tools:detectorRouter:delete`" style="color:#F56C6C;">{{$t('tools.component_CardList_tips_5')}}</el-dropdown-item>
                                    </template>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                    <div class="cover-block" @click.stop v-if="['S','O'].indexOf(task.status) < 0">
                        <template v-if="task.status === 'U'">
                            <div :class="['color-error',task.failureCode === 'PARAGRAPH_DIFFERENT' ? 'color-FF9900' : '']"><svg-icon name="ib-warning-o" className="fonts-18"></svg-icon></div>
                            <div class="margin-t-10 fonts-14" v-if="task.failureCode === 'FLOW_LACK'">{{$t('tools.component_CardList_tips_7')}}</div>
                            <div class="margin-t-10 fonts-14" v-if="task.failureCode === 'PARAGRAPH_DIFFERENT'">{{$t('tools.component_CardList_tips_8')}}<br/>{{$t('tools.component_CardList_tips_9')}}</div>
                            <div class="margin-t-10 fonts-14" v-if="task.failureCode === 'MAX_SENTENCE_PAIR_LIMIT'">{{$t('tools.component_CardList_tips_10')}}</div>
                            <template v-if="type === 'align'">
                                <div class="margin-t-20 fonts-14">
                                    <span class="color-error cursor-pointer" v-permission="`tools:alignmentRouter:delete`" @click="executeAction('delete', task)">{{$t('tools.component_CardList_tips_11')}}</span>
                                    <template v-if="task.failureCode === 'PARAGRAPH_DIFFERENT'">
                                        <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:alignmentRouter:retry`" @click="restartBtnEvent(task, 1)">{{$t('tools.component_CardList_tips_12')}}</span>
                                    </template>
                                    <template v-if="task.failureCode === 'FLOW_LACK'">
                                        <el-tooltip :content="$t('tools.component_CardList_tips_16')" placement="top">
                                            <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:alignmentRouter:buy`" @click="showPayCharDialog">{{$t('tools.component_CardList_tips_13')}}</span>
                                        </el-tooltip>
                                    </template>
                                    <template v-if="task.failureCode === 'FLOW_LACK'">
                                        <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:alignmentRouter:retry`" @click="restartBtnEvent(task, 1)">{{$t('tools.component_CardList_tips_14')}}</span>
                                    </template>
                                    <template v-if="task.failureCode === 'MAX_SENTENCE_PAIR_LIMIT'">
                                        <el-tooltip placement="top">
                                            <div slot="content">{{$t('tools.component_CardList_tips_15', {size1: task.maxLine, size2: task.sentenceCount, size3: task.sentenceCount%task.maxLine > 0 ? parseInt(task.sentenceCount/task.maxLine) + 1 : parseInt(task.sentenceCount/task.maxLine)})}}</div>
                                            <span class="color-link cursor-pointer margin-l-20">{{$t('tools.component_CardList_tips_17')}}</span>
                                        </el-tooltip>
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="type === 'termExtraction'">
                                <div class="margin-t-20 fonts-14">
                                    <span class="color-error cursor-pointer" v-permission="`tools:extractionRouter:delete`" @click="executeAction('delete', task)">{{$t('tools.component_CardList_tips_11')}}</span>
                                    <template v-if="task.failureCode === 'PARAGRAPH_DIFFERENT'">
                                        <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:extractionRouter:retry`" @click="restartBtnEvent(task, 1)">{{$t('tools.component_CardList_tips_12')}}</span>
                                    </template>
                                    <template v-if="task.failureCode === 'FLOW_LACK'">
                                        <el-tooltip :content="$t('tools.component_CardList_tips_16')" placement="top">
                                            <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:extractionRouter:buy`" @click="showPayCharDialog">{{$t('tools.component_CardList_tips_13')}}</span>
                                        </el-tooltip>
                                    </template>
                                    <template v-if="task.failureCode === 'FLOW_LACK'">
                                        <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:extractionRouter:retry`" @click="restartBtnEvent(task, 1)">{{$t('tools.component_CardList_tips_14')}}</span>
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="type === 'qa'">
                                <div class="margin-t-20 fonts-14">
                                    <span class="color-error cursor-pointer" v-permission="`tools:checkRouter:delete`" @click="executeAction('delete', task)">{{$t('tools.component_CardList_tips_11')}}</span>
                                    <template v-if="task.failureCode === 'PARAGRAPH_DIFFERENT'">
                                        <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:checkRouter:retry`" @click="restartBtnEvent(task, 1)">{{$t('tools.component_CardList_tips_12')}}</span>
                                    </template>
                                    <template v-if="task.failureCode === 'FLOW_LACK'">
                                        <el-tooltip :content="$t('tools.component_CardList_tips_16')" placement="top">
                                            <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:checkRouter:buy`" @click="showPayCharDialog">{{$t('tools.component_CardList_tips_13')}}</span>
                                        </el-tooltip>
                                    </template>
                                    <template v-if="task.failureCode === 'FLOW_LACK'">
                                        <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:checkRouter:retry`" @click="restartBtnEvent(task, 1)">{{$t('tools.component_CardList_tips_14')}}</span>
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="type === 'detector'">
                                <div class="margin-t-20 fonts-14">
                                    <span class="color-error cursor-pointer" v-permission="`tools:detectorRouter:delete`" @click="executeAction('delete', task)">{{$t('tools.component_CardList_tips_11')}}</span>
                                    <template v-if="task.failureCode === 'PARAGRAPH_DIFFERENT'">
                                        <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:detectorRouter:retry`" @click="restartBtnEvent(task, 1)">{{$t('tools.component_CardList_tips_12')}}</span>
                                    </template>
                                    <template v-if="task.failureCode === 'FLOW_LACK'">
                                        <el-tooltip :content="$t('tools.component_CardList_tips_16')" placement="top">
                                            <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:detectorRouter:buy`" @click="showPayCharDialog">{{$t('tools.component_CardList_tips_13')}}</span>
                                        </el-tooltip>
                                    </template>
                                    <template v-if="task.failureCode === 'FLOW_LACK'">
                                        <span class="color-link cursor-pointer margin-l-20" v-permission="`tools:detectorRouter:retry`" @click="restartBtnEvent(task, 1)">{{$t('tools.component_CardList_tips_14')}}</span>
                                    </template>
                                </div>
                            </template>
                        </template>
                        <template v-else-if="task.status === 'F'">
                            <div class="color-error"><svg-icon name="ib-warning-o" className="fonts-18"></svg-icon></div>
                            <div class="margin-t-10 fonts-14">{{$t('tools.component_CardList_tips_18')}}</div>
                            <div class="margin-t-20 fonts-14">
                                <span class="color-error cursor-pointer" @click="executeAction('delete', task)">{{$t('tools.component_CardList_tips_11')}}</span>
                                <template v-if="['TIME_OUT', 'NETWORK_ERROR', 'TRANSLATION_ERROR'].indexOf(task.failureCode) > -1">
                                    <span class="color-link cursor-pointer margin-l-20" @click="restartBtnEvent(task, 2)">{{$t('tools.component_CardList_tips_14')}}</span>
                                </template>
                            </div>
                        </template>
                        <div v-else><i class="el-icon-loading"></i> {{task.statusDesc}}</div>
                    </div>
                </div>
            </template>
        </div>
        <el-dialog :title="$t('tools.component_CardList_dialog_1_title')" center :visible.sync="nameDialog.show" destroy-on-close width="480px" top="20px" @close="nameDialog.name = ''">
            <el-form label-width="80px" style="margin-right:40px;">
                <el-form-item :label="$t('tools.component_CardList_dialog_1_form_item_1')" required>
                    <el-input :placeholder="$t('tools.component_CardList_dialog_1_input_placeholder_1')" v-model="nameDialog.name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button round plain size="small" @click="nameDialog.show = false">{{$t('tools.component_CardList_dialog_1_btn_1')}}</el-button>
                <el-button round type="primary" size="small" style="margin-left:30px;" :loading="nameDialog.loading" @click="saveRenameBtnEvent">{{$t('tools.component_CardList_dialog_1_btn_2')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import config from '@/utils/config';
import { mapGetters, mapState } from "vuex";
import { toolsApi } from '@/utils/api';
export default {
    props:{
        type:{
            type:String,
            default:'',
        },
        list:{
            type:Array,
            default:[]
        }
    },
    data(){
        return{
            nameDialog: {
                show: false,
                loading: false,
                name: '',
                param: {},
            }
        }
    },
    computed:{
        ...mapGetters({
			userInfo: "user/userInfo",
		}),
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    methods:{
        executeAction(key,data){
            this.$emit('action', key, data);
        },
        handleMoreCommand(command,item){
            if(command === 'rename'){
                this.nameDialog.show = true;
                this.nameDialog.name = item.title;
                this.nameDialog.param = item;
                return;
            }
            this.executeAction(command, item);
        },
        showPayCharDialog(){
            this.$router.push({path:'/account/vip',query: {view: 'char', child: true}});
        },
        /**
         * type 1 = 唤醒，2 = 重试
         */
        restartBtnEvent(task, type){
            let url = `${toolsApi.restartTask}?taskId=${task.taskId}`;
            if(type === 1){
                url = `${toolsApi.awakeTask}?taskId=${task.taskId}`;
            }
            if(this.type === 'align'){
                url = url + '&toolType=SA';
            }else if (this.type === 'termExtraction'){
                url = url + '&toolType=ET';
            }else if (this.type === 'qa'){
                url = url + '&toolType=QA';
            }else if (this.type === 'detector'){
                url = url + '&toolType=MTI';
            }
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.$message.success(this.$t('tools.component_CardList_method_restartBtnEvent_message_1'))
                }
            })
        },
        saveRenameBtnEvent() {
            if(!this.nameDialog.name) {
                this.$message.error(this.$t('tools.component_CardList_method_saveRenameBtnEvent_message_1'));
                return;
            }
            let url = toolsApi.saveAlignEditTitle;
            this.nameDialog.loading = true;
            this.$ajax.post(url, {
                taskId: this.nameDialog.param.taskId, 
                title: this.nameDialog.name
            }).then(res => {
                if(res.status === 200){
                    this.$message.success(this.$t('tools.component_CardList_method_saveRenameBtnEvent_message_2'));
                    this.nameDialog.show = false;
                    this.executeAction('reload');
                }
            }).finally(()=>{
                this.nameDialog.loading = false;
            })
        },
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.card-list-component{
    .card-list{
        margin: 0 90px;
        display: grid;
        grid-gap: 29px;
        grid-template-columns: repeat(auto-fill,minmax(290px,1fr));
        > .item{
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 16px 20px;
            position: relative;
            cursor: pointer;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            transition: border .3s;
            &:hover,&:focus{
                border: 1px solid #DFDFDF;
                box-sizing: border-box;
                > .info-content{
                    .right{
                        .type-btn{
                            display: none;
                        }
                        .more-btn{
                            display: block;
                        }
                    }
                }
            }
            > .name-status{
                display: flex;
                align-items: center;
                margin-bottom: 22px;
                > .name{
                    flex: 1;
                    font-weight: 600;
                    font-size: 15px;
                    white-space:nowrap;  
                    text-overflow:ellipsis;
                    overflow: hidden;
                }
                > .status-content{
                    width:80px;
                    display: flex;
                    justify-content: flex-end;
                    > .status{
                        width: 74px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                        border-radius: 50px;
                        font-weight: 600;
                        font-size: 12px;
                        &.finished{
                            background-color: #D4E8E2;
                        }
                        &.process{
                            background-color: #D4E4F9;
                        }
                        &.fail{
                            background-color: #FFD4D4;
                        }
                    }
                }
            }
            > .info-content{
                display: flex;
                justify-content: space-between;
                .info{
                    font-size: 12px;
                    margin-bottom: 16px;
                }
                .time{
                    font-size: 12px;
                    color:#808080;
                }
                .right {
                    display: flex;
                    align-items: flex-end;
                    .type-btn,.more-btn{
                        width:50px;
                        height:24px;
                        line-height:24px;
                        text-align:center;
                        background-color: #EEEEEE;
                        border-radius:50px;
                        svg{
                            font-size:16px;
                        }
                    }
                    .more-btn{
                        display: none;
                    }
                }
                
            }
            > .cover-block{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: #ffffffF2;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: 14px;
                cursor: default;
                .info{
                    margin-top: 10px;
                }
                .delete{
                    color: $a-color;
                    margin-top: 20px;
                    cursor: pointer;
                    &:hover{
                        color: $a-hover-color;
                    }
                }
            }
        }
    }
}
</style>
